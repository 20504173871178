<template>
  <div>
    <HeroInner descrition="Dashboard de Voluntariado" title :ShowLogo="true" />
    <div>
        

        <!--Dashboard-->
      <v-container grid-list-md fluid style="max-width:1440px">
        <v-layout row wrap justify-space-between text-xs-center style="margin-bottom: 16px !important; margin-top: 16px !important;">

          <v-flex xs12 sm6 lg2>
            <!-- item card-->
            <v-flex card column>
              <v-flex grow d-flex align-center>
                <h2>
                  {{ volunteeringInfo ? volunteeringInfo.totalVolunteerings : '' }}
                  <span>{{ volunteeringInfo ? volunteeringInfo.totalVolunteerings : '' }}</span>
                </h2>
              </v-flex>
              <v-flex card-label shrink>Total de Voluntários</v-flex>
              <div class="loader-circle br-8" v-show="loading">
                <v-progress-circular :value="20" indeterminate color="teal"></v-progress-circular>
              </div>
            </v-flex>
          </v-flex>

          <v-flex xs12 sm6 lg2>
            <!-- item card-->
            <v-flex card column>
              <v-flex grow d-flex align-center>
                <h2>
                  {{ subscribedAmount }}
                  <span> {{ subscribedAmount }}</span>
                </h2>
              </v-flex>
              <v-flex card-label shrink>Vagas Preenchidas</v-flex>
              <div class="loader-circle br-8" v-show="loading">
                <v-progress-circular :value="20" indeterminate color="teal"></v-progress-circular>
              </div>
            </v-flex>
          </v-flex>

          <v-flex xs12 sm6 lg2>
            <!-- item card-->
            <v-flex card column>
              <v-flex grow d-flex align-center>
                <h2>
                  {{ volunteeringInfo ? volunteeringInfo.totalHours : '' }}
                  <span>{{ volunteeringInfo ? volunteeringInfo.totalHours : ''}}</span>
                </h2>
              </v-flex>
              <v-flex card-label shrink>Total de horas Comprometidas</v-flex>
              <div class="loader-circle br-8" v-show="loading">
                <v-progress-circular :value="20" indeterminate color="teal"></v-progress-circular>
              </div>
            </v-flex>
          </v-flex>

          <v-flex xs12 sm6 lg2>
            <v-flex card column>
              <v-flex grow d-flex align-center>
                <h2>
                  {{ volunteeringInfo ? volunteeringInfo.totalDonateHours : '' }}
                  <span>{{ volunteeringInfo ? volunteeringInfo.totalDonateHours: '' }}</span>
                </h2>
              </v-flex>
              <v-flex card-label shrink>Total de horas doadas</v-flex>
              <div class="loader-circle br-8" v-show="loading">
                <v-progress-circular :value="20" indeterminate color="teal"></v-progress-circular>
              </div>
            </v-flex>
          </v-flex>

          <v-flex xs12 sm6 lg2>
            <v-flex card column>
              <v-flex grow d-flex align-center>
                <h2>
                  {{ volunteeringInfo ? volunteeringInfo.averageHoursDonate : '' }}
                  <span>{{ volunteeringInfo ? volunteeringInfo.averageHoursDonate : '' }}</span>
                </h2>
              </v-flex>
              <v-flex card-label shrink>Média de horas doados / Voluntário</v-flex>
              <div class="loader-circle br-8" v-show="loading">
                <v-progress-circular :value="20" indeterminate color="teal"></v-progress-circular>
              </div>
            </v-flex>
          </v-flex>

          <v-flex xs12 sm6 lg2>
            <v-flex card column>
              <v-flex grow d-flex align-center>
                <h2>
                  {{ percentageSubscribed }}%
                  <span>{{ percentageSubscribed }}%</span>
                </h2>
              </v-flex>
              <v-flex card-label shrink>% Vagas Preenchidas</v-flex>
              <div class="loader-circle br-8" v-show="loading">
                <v-progress-circular :value="20" indeterminate color="teal"></v-progress-circular>
              </div>
            </v-flex>
          </v-flex>

        </v-layout>

        <div v-for="(item, index) in campaigns" :key="'campaigns'+index">
            <!-- resumo campanhas ##################### -->
            <v-layout
              bg-white
              pa-4
              mb-3
              column
              br-4
              elevation-base
              p-relative
            >
              <v-layout class="title-section-box" row wrap>
                <v-flex xs12 sm6>
                  <h5>{{ volunteeringInfo.campaignResponsible }}</h5>
                  <h3> {{ index }}</h3>
                </v-flex>
              </v-layout>
                <v-layout values-boxes mt-4 wrap>
                  <v-flex xs12 sm6 md2>
                    <div class="item">
                      <span>Total de Voluntários</span>
                      <div v-text="item.totalVolunteerings"></div>
                    </div>
                  </v-flex>
                  <v-flex xs12 sm6 md2>
                    <div class="item">
                      <span>Vagas Preenchidas</span>
                      <div>
                        {{ campaignAmounts[index.trim().replace(/[^A-Z0-9]+/ig, "_")+'subscribedAmount'] ? campaignAmounts[index.trim().replace(/[^A-Z0-9]+/ig, "_")+'subscribedAmount'] : '0' }}
                      </div>
                    </div>
                  </v-flex>
                  <v-flex xs12 sm6 md2>
                    <div class="item">
                      <span>Total de Horas Comprometidas</span>
                      <div v-text="item.TotalHours"></div>
                    </div>
                  </v-flex>
                  <v-flex xs12 sm6 md2>
                    <div class="item">
                      <span>Total de Horas Doadas</span>
                      <div>{{ item.TotalDonateHours }}</div>
                    </div>
                  </v-flex>
                  <v-flex xs12 sm6 md2>
                    <div class="item">
                      <span>Média de horas / Voluntário</span>
                      <div v-text="item.TotalDonateHours && item.totalVolunteerings ? (item.TotalDonateHours / item.totalVolunteerings) : 0"></div>
                    </div>
                  </v-flex>
                  <v-flex xs12 sm6 md2>
                    <div class="item">
                      <span>% Vagas Preenchidas</span>
                      <div> {{ campaignAmounts[index.trim().replace(/[^A-Z0-9]+/ig, "_")+'subscriptionsTotal'] ?  Math.round(((campaignAmounts[index.trim().replace(/[^A-Z0-9]+/ig, "_")+'subscribedAmount']) * 100) /  campaignAmounts[index.trim().replace(/[^A-Z0-9]+/ig, "_")+'subscriptionsTotal'])+'%' : '0%' }}</div>
                    </div>
                  </v-flex>
                </v-layout>
              </v-layout>
              <div class="loader-circle br-8" v-show="loading">
                <v-progress-circular :value="20" indeterminate color="teal"></v-progress-circular>
              </div>
        </div>
      </v-container>
    </div>
  </div>
</template>
<script>
import HeroInner from "@/components/HeroInner.vue";
import VolunteeringService from "@/scripts/services/volunteering.service"
import VolunteeringAttributeOfferService from "@/scripts/services/volunteeringAttributeOffer.service";
import CompanyService from "@/scripts/services/company.service";
import CampaignService from "@/scripts/services/campaign.service.js";

export default {
  components: {
    HeroInner,
  },
  data() {
    return {
      volunteeringService: new VolunteeringService(),
      volunteeringAttributeOfferService: new VolunteeringAttributeOfferService(),
      companyService: new CompanyService(),
      campaignService: new CampaignService(),
      companyId: null,
      volunteeringInfo: null,
      loading: false,
      institutions: {},
      volunteeringsOffers: [],
      subscribedAmount: 0,
      subscriptionsTotal: 0,
      percentageSubscribed: 0,
      institutionAmounts: {},
      campaignAmounts: {},
      campaigns: null,
    };
  },
  async mounted() {
    await this.getCompanyId();
    await this.getAllVolunteeringInformation();
    await this.getOffers();
    await this.countVolunteering();

    this.campaigns = await this.getVolunteeringsInformations();
  },
  methods: {
    async getAllVolunteeringInformation() {
      this.volunteeringInfo = await this.volunteeringService.getAllVolunteeringReport(this.companyId)
    },
    async getVolunteeringsInformations() {
      let campaignInformation = {}
      for (const item of this.volunteeringsOffers) {      
        await this.volunteeringService.getVolunteeringInformationReport(item.subdomain).then((data) => {
          // If campaign hasn't any offer inside
          if (data.length === 0) {
              campaignInformation[item.subdomain] = {
                TotalHours: 0,
                TotalDonateHours: 0,
                totalVolunteerings: 0,
                averageHoursDonate: 0,
            }
          }
          // if campaign has offer inside
          for (const offer of data) {
              const campaignIndex = Object.keys(campaignInformation).indexOf(item.subdomain)
              if (campaignIndex === -1) {
                
                campaignInformation[item.subdomain] = {
                  TotalHours: offer.totalHours,
                  TotalDonateHours: offer.totalDonateHours,
                  totalVolunteerings: offer.totalVolunteerings,
                  averageHoursDonate: offer.averageHoursDonate,
                }
              }
              else {
                const hours = campaignInformation[item.subdomain].TotalHours
                const donateHours = campaignInformation[item.subdomain].TotalDonateHours
                const averageHoursDonate = campaignInformation[item.subdomain].averageHoursDonate

                campaignInformation[item.subdomain] = {
                  TotalHours: hours + offer.totalHours,
                  TotalDonateHours: donateHours + offer.totalDonateHours,
                  totalVolunteerings: offer.totalVolunteerings,
                  averageHoursDonate: averageHoursDonate + offer.averageHoursDonate,
                }
              }
            }
          })
        }
        
        return campaignInformation
    },
    async getOffers() {
      this.volunteeringsOffers = []
      await this.campaignService.listAllByCompanyId(this.companyId).then(async (data) => {
          this.volunteeringsOffers = await this.selectOnlyVolunteerings(data)
      })
    },
    async selectOnlyVolunteerings(data) { // select only campaign with volunteerings type id
      const campaigns = []
      for (const campaign of data) {
        if (campaign.campaignTypeId === 4) campaigns.push(campaign)
      }
      return campaigns;
    },
    async countVolunteering() {
      await this.volunteeringAttributeOfferService.listVolunteeringAttributeOfferByCompanyId(this.companyId).then((data) => {
        this.subscribedAmount = 0;
        this.subscriptionsTotal = 0;
        for (const offer of data) {
          if (offer.isDeleted === false) {
            const campaignName = (offer.subdomain).trim().replace(/[^A-Z0-9]+/ig, "_");
            this.subscribedAmount += offer.registrationAmount;
            this.subscriptionsTotal += offer.totalOfferAmount;

            if (!this.campaignAmounts[campaignName+'subscribedAmount']) this.campaignAmounts[campaignName+'subscribedAmount'] = 0
            if (!this.campaignAmounts[campaignName+'subscriptionsTotal']) this.campaignAmounts[campaignName+'subscriptionsTotal'] = 0

            this.campaignAmounts[campaignName+'subscribedAmount'] += offer.registrationAmount;
            this.campaignAmounts[campaignName+'subscriptionsTotal'] += offer.totalOfferAmount;
          }
        }
        if (this.subscribedAmount && this.subscriptionsTotal)
        this.percentageSubscribed = Math.round((this.subscribedAmount * 100) /  this.subscriptionsTotal)
        
        
      })
    },
    async getCompanyId() {
      await this.companyService.findByEntityId(this.$route.query.entityId).then((data) => {
        this.companyId = data.id
      });
    }
  },
};
</script>